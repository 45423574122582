@import url(https://fonts.googleapis.com/css?family=Roboto);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1 {
  font-size: 2em;
  margin-bottom: 1em; }

h2 {
  font-size: 1.5em;
  margin-bottom: 1em; }

strong {
  font-weight: 700; }

.container {
  margin-top: 2em; }
  .container header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em; }

.app-container {
  height: 100%; }

.route-container {
  overflow: auto;
  padding: 1.4em 3em; }
  @media only screen and (max-width: 640px) {
    .route-container {
      padding: 1em; } }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  font-size: .7em; }

body, #root {
  height: 100%;
  overflow: hidden;
  width: 100%; }

