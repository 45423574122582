@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  font-size: .7em;
}

body, #root {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
