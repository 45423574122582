h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

strong {
  font-weight: 700;
}

.container {
  margin-top: 2em;

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
}
